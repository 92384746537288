
import { Message } from 'element-ui'
import Vue from 'vue'
Vue.mixin({
  data () {
    return {
    }
  },
  methods: {
    MessageSystem (text, type, title) {
      let icon = ''
      if (type === 'success') {
        icon = '<span style="color: var(--secondary-green-700);font-size: 20px;" class="icon-checkmark-circle"></span>'
      } else if (type === 'warning') {
        icon = '<span style="color: var(--secondary-orange-700);font-size: 20px;" class="icon-warning"></span>'
      } else if (type === 'message') {
        icon = '<span style="color: var(--Neutral-700);font-size: 20px;" class="icon-error"></span>'
      } else if (type === 'error') {
        icon = '<span style="color: var(--secondary-red-700);font-size: 20px;" class="icon-dismiss"></span>'
      }
      return Message({
        showClose: true,
        dangerouslyUseHTMLString: true,
        iconClass: 'none',
        duration:'4000',
        type: type,
        message: !title ? 
        `<div class="message message-${type}"><p class="content-toast">${icon} <span>${text} </span></p> </div>`: 
        `<div class="message message-${type}"><div class="content-toast ">${icon}<div><div class="body-small-semi-bold neutral-700"><p>${title}</p> <p class="is-text-content body-small-regular neutral-600">${text}</p><div></div> </div>`,
        customClass: `toast toast-${type}` 
      })
    }
  }
})
