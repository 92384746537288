<template>
    <el-row class="left-section-sidebar">
        <div>
            <p class="is-page">Pages</p>
        </div>
        <el-menu :default-active="$route.path" class="el-menu-vertical-demo">
            <el-menu-item
                v-for="(menu, index) in menus"
                :key="index"
                :index="menu.path"
                :class="setActive(menu) ? 'is-active' : ''"
            >
                <el-link :href="menu.path">
                    <i
                        :style="{
                            visibility: menu.active ? 'visible' : 'hidden',
                        }"
                        class="el-icon-check"
                    ></i>
                    <span>{{ menu.title }}</span>
                </el-link>
            </el-menu-item>
        </el-menu>
    </el-row>
</template>

<script>
export default {
    name: "Sidebar",
    components: {},
    data() {
        return {
            menus: [
                {
                    title: "icon",
                    path: "/hotel/sadmin/icon",
                    index: 99,
                    active: true,
                },
                {
                    title: "color",
                    path: "/hotel/sadmin/color",
                    index: 0,
                    active: true,
                },
                {
                    title: "Typography",
                    path: "/hotel/sadmin/typography",
                    index: 1,
                    active: true,
                },
                {
                    title: "CTA",
                    path: "/hotel/sadmin/cta",
                    index: 2,
                    active: true,
                },
                {
                    title: "Input",
                    path: "/hotel/sadmin/input",
                    index: 3,
                    active: true,
                },
                {
                    title: "ToolTip",
                    path: "/hotel/sadmin/tool-tip",
                    index: 98,
                    active: true,
                },
                {
                    title: "Toggles",
                    path: "/hotel/sadmin/toggles",
                    index: 97,
                    active: true,
                },
                {
                    title: "Table",
                    path: "/hotel/sadmin/table",
                    index: 8,
                    active: true,
                },
                {
                    title: "ToastMessage",
                    path: "/hotel/sadmin/toast-message",
                    index: 47,
                    active: true,
                },
                {
                    title: "Drawer",
                    path: "/hotel/sadmin/drawer",
                    index: 48,
                    active: true,
                },
                {
                    title: "Illustration",
                    path: "/hotel/sadmin/illustration",
                    index: 50,
                    active: true,
                },
                {
                    title: "Checkbox & Radio",
                    path: "/hotel/sadmin/checkbox-radio",
                    index: 49,
                    active: false,
                },
                {
                    title: "Select",
                    path: "/hotel/sadmin/select",
                    index: 4,
                    active: false,
                },
                {
                    title: "Radio",
                    path: "/hotel/sadmin/radio",
                    index: 5,
                    active: false,
                },
                {
                    title: "Checkbox",
                    path: "/hotel/sadmin/checkbox",
                    index: 6,
                    active: false,
                },
                {
                    title: "Switch",
                    path: "/hotel/sadmin/switch",
                    index: 7,
                    active: false,
                },

            ],
        };
    },
    methods: {
        setActive(menu) {
            if (this.$router.currentRoute.fullPath === menu.path) {
                return true;
            } else {
                return false;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.left-section-sidebar:deep(.el-menu-item) {
    height: 45px;
    line-height: 45px;
}
.el-link {
    display: inherit !important;
}
.left-section-sidebar {
    .is-page {
        width: 100%;
        font-size: 18px;
        font-weight: bold;
        padding: 32px 0 8px 24px;
    }
    .el-menu-vertical-demo {
        height: calc(100vh - 100px);
        li {
            a:hover {
                text-decoration: none !important;
            }
        }
        .el-link--inner::after {
            display: none;
        }
        .el-link.el-link--default:after {
            display: none;
        }
    }
}
.is-active {
    a {
        color: #ff6400 !important;
        text-decoration: none !important;
    }
}
</style>
