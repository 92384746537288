const initRouter = async function () {
    await import("./color");
    await import("./typography");
    await import("./cta");
    await import("./input");
    await import("./select");
    await import("./radio");
    await import("./checkbox");
    await import("./switch");
    await import("./icon");
    await import("./table-system");
    await import("./tool-tip");
    await import("./toggles");
    await import("./toast-message")
    await import('./drawer');
    await import('./checkbox-radio');
    await import('./illustration');
};
initRouter();
